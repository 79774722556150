<template>
  <div class="home">
    <left-sidebar />
    <div class="post main">
      <h3 class="title">{{ postInfo.title }}</h3>
      <opened-post v-if="postInfo.id" :postInfo="postInfo" />
      <div v-else class="w-100 d-flex justify-content-center">
        <b-spinner variant="success"></b-spinner>
      </div>
    </div>
    <right-sidebar />
  </div>
</template>

<script>
import LeftSidebar from "../../components/LeftSidebar.vue";
import OpenedPost from "../../components/OpenedPost.vue";
import RightSidebar from "../../components/RightSidebar.vue";

export default {
  components: { OpenedPost, LeftSidebar, RightSidebar },
  metaInfo() {
    return {
      title: this.postInfo.title,
      meta: [
        { name: "description", content: this.postInfo.description },
        { property: "og:title", content: this.postInfo.title },
        { property: "og:site_name", content: "techvibe" },
        { property: "og:image", content: this.postInfo.thumbnail },
        { property: "og:type", content: "website" },
      ],
      htmlAttrs: {
        lang: this.currLang,
      },
    };
  },
  data() {
    return {
      postInfo: {},
    };
  },
  async mounted() {
    try {
      this.postInfo = await this.$store.dispatch("getNewById", {
        id: this.$route.params.id,
        draft: this.$route.query.draft,
      });
    } catch (e) {
      this.error(this.t("failed2openPost"));
      this.$router.push("/");
    }
  },
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 700;
  color: black !important;
}
.post {
  text-align: start;
  background: #ffffff;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  backdrop-filter: blur(32px);
  border-radius: 15px;
  padding: 16px;
  max-width: 790px;
  width: 790px;
  margin: 20px 0;
}
.top,
.bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  p {
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 0;
  }
  .time {
    margin-left: 3%;
    font-size: 14px;
    opacity: 0.4;
  }
}
.bottom {
  margin-top: 10px;
  border-top: 2px solid rgba(128, 128, 128, 0.158);
  padding-top: 10px;
  padding-bottom: 20px;
}
.tags-row {
  padding-left: 10px;
  .tag {
    cursor: pointer;
    font-weight: bold;
    background-color: #cecece;
    border-radius: 10px;
    color: white;
    margin: 5px;
    width: max-content;
  }
}
.thumbnail {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}
.content {
  max-width: 100%;
  word-wrap: break-word;
  margin: 10px 5px;
  margin-bottom: 20px;
}
.actions-row {
  display: flex;
  .mutted {
    opacity: 0.5;
  }
  .share {
    img {
      height: 23px;
    }
  }
  .delete {
    margin-left: auto;
    img {
      height: 20px;
    }
  }
  span {
    font-weight: bold;
    margin-left: 4px;
    color: var(--itpark);
  }
}
.actions-row > * {
  cursor: pointer;
  margin: 10px;
}
@media (max-width: 767px) {
  .main {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    border-radius: 0 !important;
    border: none !important;

    h3 {
      font-size: 24px !important;
    }
  }
}
</style>